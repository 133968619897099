import React from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";

import { useAppConfig } from "../appConfig";
import { STORAGE_KEYS } from "../../constants";

const missingProviderError =
  "You forgot to wrap your component in <LocalSessionProvider>.";

const LocalSessionContext = React.createContext({
  get setItem() {
    throw new Error(missingProviderError);
  },
  get getItem() {
    throw new Error(missingProviderError);
  },
  get removeItem() {
    throw new Error(missingProviderError);
  },
});
LocalSessionContext.displayName = "LocalSessionContext";

const updateCookieStorage = (newStorage) => {
  if (typeof window !== "undefined") {
    Cookies.set(STORAGE_KEYS.LOCAL_SESSION, JSON.stringify(newStorage), {
      path: "/",
      sameSite: "strict",
      secure: true,
    });
  }
};

export function LocalSessionProvider({ initialValue = {}, children }) {
  const [storage, setStorage] = React.useState(initialValue);
  const { appConfig } = useAppConfig();

  const setItem = (key, value) => {
    const newStorage = { ...storage, [key]: value };
    setStorage(newStorage);
    updateCookieStorage(newStorage);
  };

  const getItem = (key) => storage[key];

  const removeItem = (key) => {
    const { [key]: removed, ...newStorage } = storage;
    setStorage(newStorage);
    updateCookieStorage(newStorage);
  };

  React.useEffect(() => {
    const localSessionValue = Cookies.get(STORAGE_KEYS.LOCAL_SESSION);
    setStorage(localSessionValue ? JSON.parse(localSessionValue || "{}") : {});
  }, []);

  React.useEffect(() => {
    const handleUnload = () => {
      if (appConfig.tabCount === 1 || appConfig.tabCount === 0) {
        Cookies.remove(STORAGE_KEYS.LOCAL_SESSION, { path: "/" });
      }
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [appConfig.tabCount]);

  return (
    <LocalSessionContext.Provider value={{ setItem, getItem, removeItem }}>
      {children}
    </LocalSessionContext.Provider>
  );
}

export function useLocalSession() {
  return React.useContext(LocalSessionContext);
}

LocalSessionProvider.propTypes = {
  initialValue: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
