import useCurriculum from "./useCurriculum";
import { useUser } from "../contexts/user";

export default function useHasCurriculumAccess() {
  const { user } = useUser();
  const curriculum = useCurriculum();

  if (user.features.connectedPianoApi && !!curriculum) {
    // eslint-disable-next-line no-console
    console.error("not connected to Piano yet");
    return null;
  }

  return !!curriculum;
}
