import React from "react";
import PropTypes from "prop-types";
import Script from "next/script";

import BugsnagClient from "../../utils/bugsnag";

const missingTpProvider = "You forgot to wrap your app in <PianoProvider>";

const PianoContext = React.createContext();

PianoContext.displayName = "PianoContext";

export function PianoProvider({ children }) {
  const [piano, setPiano] = React.useState([]);
  return (
    <PianoContext.Provider value={piano}>
      <Script
        src={`${process.env.NEXT_PUBLIC_PIANO_SCRIPT_URL}?aid=${process.env.NEXT_PUBLIC_PIANO_APPLICATION_ID}`}
        onLoad={() => setPiano(window.tp)}
        onError={(event) => {
          BugsnagClient.notify({
            name: "Piano script failed to load",
            message: `${event.target.src} failed to load`,
          });
        }}
        strategy="afterInteractive"
      />
      {children}
    </PianoContext.Provider>
  );
}

export function usePiano() {
  const piano = React.useContext(PianoContext);
  if (!piano) {
    throw new Error(missingTpProvider);
  }
  return piano;
}

PianoProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

PianoProvider.defaultProps = {
  children: null,
};
