import React from "react";
import { useQueryClient } from "@tanstack/react-query";

import { useUser } from "../contexts/user";
import { useLocalSession } from "../contexts/LocalSession";
import { QUERY_KEYS, STORAGE_KEYS } from "../constants";

export default function useProfile() {
  const queryClient = useQueryClient();
  const { user, updateUser } = useUser();
  const { setItem, getItem } = useLocalSession();

  const hasSelectedProfile = !!getItem(STORAGE_KEYS.HAS_SELECTED_PROFILE);

  const setHasSelectedProfile = React.useCallback(
    (value) => {
      setItem(STORAGE_KEYS.HAS_SELECTED_PROFILE, value);
    },
    [setItem],
  );

  React.useEffect(() => {
    // Clear the selected profile if the user is not logged in
    if (!user?.isLoggedIn && hasSelectedProfile) {
      setHasSelectedProfile(false);
    }
  }, [hasSelectedProfile, setHasSelectedProfile, user?.isLoggedIn]);

  const selectedProfile = user?.classrooms?.find(
    (profile) => profile.id === user?.selectedClassroomId,
  );

  const selectProfile = React.useCallback(
    (profileId, options = {}) => {
      const { onSuccess = () => {}, ...rest } = options;
      updateUser(
        { selectedClassroomId: profileId },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries([QUERY_KEYS.FAVORITES]);
            setHasSelectedProfile(true);
            onSuccess();
          },
          ...rest,
        },
      );
    },
    [setHasSelectedProfile, updateUser, queryClient],
  );

  return {
    profile: selectedProfile,
    hasSelectedProfile,
    selectProfile,
  };
}
